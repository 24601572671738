import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Events represent an action performed at a specific point in time on behalf of an individual Profile, and are the
primary method of sending data to Firstparty. Events describe who, how, and when an activity happened in your business.`}</p>
    <p>{`The most common event, collected automatically when Firstparty.js is installed on your website, is a "Page Viewed" Event.
This fundamental metric informs you of traffic. Adding custom Events will give you much more feedback on the usage of
your website or product. Common event examples on a website are "Page Viewed", "Form Submitted", and "Button Clicked".`}</p>
    <h3 {...{
      "id": "why-events",
      "style": {
        "position": "relative"
      }
    }}>{`Why Events`}<a parentName="h3" {...{
        "href": "#why-events",
        "aria-label": "why events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Events naturally happen on every website, web application, mobile application, and software product. If you are not
collecting Events, they still happen, but you will not be informed with data on the usage patterns around
that Event. Tracking Events can dramatically enhance your fact-based decision making. Will you
deprioritize work on a feature that is rarely used? Should you focus on a changing a page that has a high bounce rate,
but is your most popular page? Do your users' navigation patterns change if you change the text of a label? These
questions can be answered with the help of collecting and later reporting on Events.`}</p>
    <p>{`As the property owner, the level of detail you collect is up to you to decide. Only you know what's important to your
business, and what actions your users will take. As a general starting point, you'll want to Track Events that show
user intent and affect state change. Abandoning a populated shopping cart can be more important than initiating a
customer service ticket, but both are important. Without Event collecting, understanding your user behavior is much
more difficult if not impossible.`}</p>
    <h3 {...{
      "id": "structure",
      "style": {
        "position": "relative"
      }
    }}>{`Structure`}<a parentName="h3" {...{
        "href": "#structure",
        "aria-label": "structure permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`To submit a custom event, you'll simply POST a bit of JSON to your Firstparty account. The minimum required data needed
is the name of your Event, and a Write Key. The Write key is your public key that ties your Source in Firstparty to the
Event you are sending in. The minimum JSON looks like this.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"event"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Newsletter Signup"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"write_key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"_______"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3 {...{
      "id": "write-keys",
      "style": {
        "position": "relative"
      }
    }}>{`Write Keys`}<a parentName="h3" {...{
        "href": "#write-keys",
        "aria-label": "write keys permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Write Keys are created for you when you create a new Source. You may have many sources in each
Workspace to collect. Example sources might be your www Marketing Page, a Help Desk application, a Web Application, an
IoS Application and an Android Application. This example Workspace can collect events from five different sources.`}</p>
    <p>{`While the Event name and write key are the two required key value pairs, there are also optional data you can include
with your Event. These are Properties and Context.`}</p>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Properties on an Event are immutable after all Rules have run immediately after collecting the Event.
Properties is a simple Key/Value store with any arbitrary data that is worth collecting for this user. This could be
an account number, first+middle+last names, email addresses, or any other supporting information available that may
make understanding and acting on these events in the future. Here is a sample JSON payload for "Agent Assigned" that
contains supporting Properties.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"event"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Agent Assigned"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"write_key"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"_______"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"properties"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"email"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"sally@example.com"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"display_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Sally"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"initiated_from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Chat Widget"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"subject"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Help with my billing"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This payload enhances the Event with additional Properties known at the time the Event occurred.`}</p>
    <p>{`Remember an Event has to be posted with a Write Key. This identifies which Source the Event is happening in, and will
be written to. An Event can only have one Source, and later you'll be able to query Events by suppling a SourceId. This
will return your filtered Events that happened inside that Source.`}</p>
    <h3 {...{
      "id": "best-practices-when-naming-events",
      "style": {
        "position": "relative"
      }
    }}>{`Best Practices when Naming Events`}<a parentName="h3" {...{
        "href": "#best-practices-when-naming-events",
        "aria-label": "best practices when naming events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`When creating Events, you'll also need to supply an Event Name. This can be any UTF-8 String name you desire but having
a standard will help you later when creating filters. Firstparty recommends the following naming Guidelines for Event
Names.`}</p>
    <ul>
      <li parentName="ul">{`Event names should be combination of "Noun" plus "Past tense Verb"`}</li>
      <li parentName="ul">{`Title Case each word`}</li>
      <li parentName="ul">{`A-Z only, no other special characters`}</li>
      <li parentName="ul">{`Use a single space between each word`}</li>
    </ul>
    <p>{`"Page Viewed" is the correct format, where the following would be considered bad practice.`}</p>
    <ul>
      <li parentName="ul">{`"form submitted": Not correctly title cased`}</li>
      <li parentName="ul">{`"Button clicked": Not correctyl title cased`}</li>
      <li parentName="ul">{`"Product 126": Using numbers in Event names is a bad idea`}</li>
      <li parentName="ul">{`"Cart-Abandoned": Using special characters in Event names is a bad idea`}</li>
    </ul>
    <p>{`You may trigger as many Events as you'd like - there are no rate limits or constraints.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      